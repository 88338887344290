import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { useAuth } from 'hooks/useAuth'
import useBonus from 'hooks/useBonus'
import { useAlert } from 'hooks/useAlert'

import * as CatalogApi from 'services/api/CatalogApi'
import { REQUEST_VALIDATED } from 'utils/domain/request'

import { getColor } from 'utils/styles'
import { formatNumberAndAddEurCurrency } from 'utils/number'

import PublicLayout from 'layouts/Public'

import { Markdown } from 'components/shared/Markdown'
import DangerIcon from 'components/svg/Danger'
import Table from 'components/shared/Table'

import { bonusColumns } from './bonusColumns'
import './style.scss'
import Spinner from 'react-svg-spinner'

const PRIME_CONSUMED_MESSAGE = 'Attention, vous avez utilisé toute votre prime disponible, aucune prime ne vous sera versée pour les prestations que vous commanderez ultérieurement'

const MyBonusPage = () => {
    const { user } = useAuth()
    const { addAlert } = useAlert()
    const { getRequestsWithBonus, getBonusByRequests } = useBonus()

    const [requests, setRequests] = useState([])
    const [isBonusReady, setIsBonusReady] = useState(false)
    const handleGetRequest = async () => {
        try {
            const { res: requests, getError: getRequestsError } = await CatalogApi.getRequests()
            if (getRequestsError()) throw getRequestsError()

            const availableRequests = await getRequestsWithBonus(requests, user?.entity_data?.structureEmployeeQuantity)
            const filteredValidRequests = availableRequests.filter(({ amount, bonus }) => amount !== 0 || bonus !== 0)

            const { res: regularisationFoldersResponse, getError: getRegularisationFoldersError } = await CatalogApi.getRegularisationFolders(user?.entity_id)
            if (getRegularisationFoldersError()) throw getRegularisationFoldersError()

            // Adjust the regularisation folder structure to the request structure to fit the table
            const mappedRegularisationFolders = (regularisationFoldersResponse ?? []).map(folder => ({
                genderLabel: 'Régularisation',
                request_fub_id: folder?.request_fub_id,
                create_date: folder?.create_date,
                amount: '-',
                bonus: +folder?.regularization_data?.amount,
                realBonus: +folder?.regularization_data?.real_amount_to_refund,
                real_amount_to_refund: +folder?.regularization_data?.real_amount_to_refund,
                statusLabel: folder?.status === 0 ? 'Validé' : 'Payé',
                regularisation_status: folder?.status,
                // eq of validated request
                status: folder?.status === 0 ? REQUEST_VALIDATED : 0
            }))

            setRequests([
                ...filteredValidRequests,
                ...mappedRegularisationFolders
            ])
            setIsBonusReady(true)
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    useEffect(() => {
        handleGetRequest()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        availableBonus,
        isPrimeTotallyConsumed,
        assignedBonus,
        remainingBonus
    } = getBonusByRequests({ requests, userEntityData: user.entity_data })

    return (
        <PublicLayout showHeader className="bonus-page">
            <div className="l-container-xxl">
                <h1 className="c-h1 u-mg-top-xl u-bold u-secondary u-center u-mg-bottom-l u-uppercase">Ma prime</h1>

                <div className="u-flex u-mg-bottom-l">
                    <div className="u-mg-right-auto u-blue u-fs-m u-bold">
                        Ma prime initiale : <span className="u-mg-left-s u-green90">{formatNumberAndAddEurCurrency(availableBonus)}</span>
                    </div>
                    <div
                        className="u-mg-left-auto u-blue u-fs-m u-bold u-flex"
                        data-tip={isPrimeTotallyConsumed ? PRIME_CONSUMED_MESSAGE : ''}
                    >
                        <div>
                            Ma prime disponible :<br/>
                            <div className="u-fs-xs">(restant à engager)</div>
                        </div>
                        <div>
                        {isBonusReady ?
                            <span className="u-mg-left-s u-mg-right-s u-green90">{formatNumberAndAddEurCurrency(remainingBonus)}</span>
                            :<Spinner size="18px" />}
                        </div>
                        { isPrimeTotallyConsumed && <DangerIcon /> }
                    </div>
                    <ReactTooltip backgroundColor={getColor().primary} />
                </div>

                <Table
                    className="bonus-border u-right"
                    columns={bonusColumns}
                    data={requests}
                    rowClassName='u-pd-vt-m u-fs-xs'
                />

                <div className="u-flex u-mg-top-l">
                    <div className="u-mg-left-auto u-blue u-fs-m u-bold u-mg-right-xxl bonus-border u-pd-vt-s u-pd-hz-xl">
                        Total prime (validée + engagée) : <span className="u-mg-left-s u-green90">{formatNumberAndAddEurCurrency(assignedBonus)}</span><br/>
                    </div>
                </div>

                <Markdown className="u-mg-top-xl u-mg-bottom-xxl">
{`
##### Le tableau ci-dessus récapitule l'ensemble de vos prestations commandées par l'intermédiaire de la plateforme et la prime associée à chacune ce ces prestations.

- Le statut "engagé" indique que la prestation n’a pas encore été vérifiée par le programme. Le montant de la prime correspondante n’est donc qu’indicatif. Il ne tient notamment pas compte des plafonds qui existent pour les équipements, ou d’éventuelles lignes de devis qui ne seraient pas prises en charge par le programme (ex : travaux de terrassement).
- Le statut "validé" indique que la prestation a été validée par le programme. Le montant de la prime correspondante est donc le montant réel de la prime qui vous sera versée pour cette prestation, dans la limite globale de votre prime initiale.
- Le statut "payé" indique que la prestation a été remboursée par le programme.
- Le montant "ma prime disponible" vous permet d’estimer votre montant restant à utiliser dans le cadre du programme. Il correspond à la différence entre votre prime initiale et le total de vos primes validées et engagées. Si le montant de prime disponible est égal à 0, vous pouvez toujours commander des prestations depuis la plateforme mais vous ne percevrez plus de prime pour celles-ci.
`}
                </Markdown>
            </div>

        </PublicLayout>
    )
}

export default MyBonusPage
