import React from 'react'
import { NavLink } from 'react-router-dom'

import CatalogRoutes from 'components/catalog/CatalogRoutes'
import ProtectedLayout from 'layouts/Protected'
import { privateRoutes } from 'router/routes'
import { isNotEmpty } from 'utils/validation'
import { useAuth } from 'hooks/useAuth'

import dateService from 'services/date'

const HomePage = () => {
    const { user } = useAuth()
    const [endedDates, setEndedDates] = React.useState(null)

    const fetchEndedDate = async () => {
        // Fetch distant document
        try {
            const response = await fetch(process.env.REACT_APP_UPDATED_DATES_URL)
            if (!response.ok) throw new Error('Failed to fetch updated dates')
            const data = await response.json()
            if(data) setEndedDates(data.dates)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => { fetchEndedDate()}, [])

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container">
                <h1 className="c-h1 u-secondary u-bold u-center u-mg-bottom-xl">Que voulez-vous faire ?</h1>

                <div className="l-grid">
                    {CatalogRoutes.map((item, index) => {
                        const currentEndedDate = endedDates?.[`requests-${item.id}-end-date`]
                        const isCurrentEndedDatePassed = currentEndedDate && Date.now() > new Date(currentEndedDate).getTime() + 86400000
                        const isStepAvailable = user?.entity_data?.[item.boSetting]
                        //const isCurrentCatalogBlockedForUser = (isCurrentEndedDatePassed  === true && isCurrentCatalogBlockedByBackOffice ===true)
                        
                        /*let isCurrentCatalogBlockedForUser = false
                        if (isCurrentCatalogBlockedByBackOffice === true) {
                            isCurrentCatalogBlockedForUser = true
                        } else {
                            if (isCurrentEndedDatePassed) {
                                isCurrentCatalogBlockedForUser = true
                            } else {
                                isCurrentCatalogBlockedForUser = false
                            }
                        }*/

                        let isCurrentCatalogBlockedForUser = false
                        if (isStepAvailable === false) {
                            isCurrentCatalogBlockedForUser = true
                        } else {
                            if (isCurrentEndedDatePassed) {
                                if (isStepAvailable === undefined) {
                                    isCurrentCatalogBlockedForUser = true
                                } else if ((isStepAvailable === true)) {
                                    isCurrentCatalogBlockedForUser = false
                                } else if (isStepAvailable === false ){
                                    isCurrentCatalogBlockedForUser = true
                                }
                                // si pas renseigné par le bo alors isStepBlockedForUser = false
                            } else {
                                isCurrentCatalogBlockedForUser = false
                            }
                        }
                        return (
                            <div className="l-col-12 l-col-6@main u-pd-m" key={index}>
                                {isCurrentCatalogBlockedForUser &&
                                    <div
                                        className={'c-catalog-card  u-pd-m u-pd-l@main ' + (!item.url || !item.componentSelection ? 'u-disabled' : '')}
                                    >
                                        <h3 className="c-h4 u-mg-bottom-m">{item?.thumbnail?.label}</h3>
                                        {item?.endedDateText.map((d, i) => (<p className="u-mg-bottom-s u-danger" key={i}>{d}</p>))}
                                        {item?.thumbnail?.picture && <img src={item.thumbnail.picture} alt={item?.thumbnail?.label} />}
                                        
                                        {isNotEmpty(currentEndedDate) && 
                                            <div className="u-flex u-flex-center-hz u-mg-top-s u-bg-danger u-white u-pd-s u-fs-xxs">
                                                <p className="u-center">Dernière demande le  </p>
                                                <div className="u-center u-mg-left-s u-bold">{dateService.formatDate(currentEndedDate)}</div>
                                            </div>
                                        }
                                    </div>
                                }
                                {!isCurrentCatalogBlockedForUser &&
                                    <NavLink
                                        to={`${privateRoutes.catalog.path}/${item.url}`}
                                        className={'c-catalog-card  u-pd-m u-pd-l@main ' + (!item.url || !item.componentSelection ? 'u-disabled' : '')}
                                    >
                                        <h3 className="c-h4 u-mg-bottom-m">{item?.thumbnail?.label}</h3>
                                        {item?.thumbnail?.description.map((d, i) => (<p className="u-mg-bottom-s" key={i}>{d}</p>))}

                                        {item?.thumbnail?.picture && <img src={item.thumbnail.picture} alt={item?.thumbnail?.label} />}
                                        
                                        {isNotEmpty(currentEndedDate) && 
                                            <div className="u-flex u-flex-center-hz u-mg-top-s u-bg-danger u-white u-pd-s u-fs-xxs">
                                                <p className="u-center">Dernière demande le  </p>
                                                <div className="u-center u-mg-left-s u-bold">{dateService.formatDate(currentEndedDate)}</div>
                                            </div>
                                        }
                                    </NavLink>
                                }

                            </div>
                        )
                    })}
                </div>
            </div>
        </ProtectedLayout>
    )
}

export default HomePage
