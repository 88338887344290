import React from 'react'
import { objectOf, shape, string, func } from 'prop-types'

import { getKeywords } from 'utils/domain/scopingMeetings'

import UnlockIcon from 'components/svg/Unlock'
import LockIcon from 'components/svg/Lock'

const capitalizeWords = (str) => {
    if (!str || typeof str !== 'string') return ''
    return str
        .split('-') // Séparer les mots par le délimiteur `-`
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliser chaque mot
        .join('') // Reformer la chaîne avec le délimiteur
}

const ItemSetting = ({ beneficiary, updateBeneficiary, endedDates, type }) => {
    // De base, le bénéficiaire ne possède pas de valeur pour les settings. 
    // Pour définir la valeur de départ, on regarde si la date de fin est dépassée pour chaque setting
    // Si c'est le cas, on met la valeur "isActivated" à false, sinon à true
    // Une fois qu'un setting est enregistré dans l'entity_data, c'est cette valeur qui est utilisée
    const { entity_data } = beneficiary
    const [isLoading, setIsLoading] = React.useState(false)

    const endedDateType = endedDates?.[`${type}-end-date`]
    const isEndedDateTypePassed = endedDateType && Date.now() > new Date(endedDateType).getTime() + 86400000

    // Construire la clé dynamiquement
    const currentKey = 'is' + capitalizeWords(type) + 'Activated'
    const isTypeActivated = entity_data[currentKey] ?? (!isEndedDateTypePassed) 

    const updateBeneficiarySettings = async () => {
        setIsLoading(true)
        try {   
            // Key with format 'is$typeActivated'
            const entity_data = {
                ...beneficiary.entity_data,
                [currentKey]: !isTypeActivated
            }
            const payload = {
                entity_id: beneficiary.entity_id,
                status: beneficiary.status,
                last_row_hash: beneficiary.row_hash,
                keywords: getKeywords(beneficiary),
                entity_data
            }
            await updateBeneficiary(payload, false)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }

    }

    return (
        <div className="u-flex u-flex-end u-mg-top-s"><button type="button" onClick={() => updateBeneficiarySettings()} disabled={isLoading}>{isTypeActivated ? <UnlockIcon /> : <LockIcon />}</button></div>
    )
}

ItemSetting.propTypes = {
    beneficiary: shape({
        requestsGroupedByGender: objectOf(
            shape({
                request_id: string.isRequired,
                status: string.isRequired
            })
        )
    }),
    updateBeneficiary: func,
    endedDates: objectOf(string),
    type: string
}


export default ItemSetting
