import React from 'react'
import { NavLink } from 'react-router-dom'

import * as UtilsApi from 'services/api/UtilsApi'
import { isNotEmpty } from 'utils/validation'

const MAX_SIGNUP_DATE = 20241231235900000


const RegisterBlock = ({ className }) => {
    const [isSignupAvailable, setIsSignupAvailable] = React.useState(true)
    const [isSignupOpen, setIsSignupOpen] = React.useState(true)

    const fetchUpdatedDates = async () => {
        // On récupère la date de fin d'inscription
        // Si la date actuelle est supérieure à la date de fin d'inscription, on ferme les inscriptions
        try {
            const response = await fetch(process.env.REACT_APP_UPDATED_DATES_URL)
            if (!response.ok) throw new Error('Failed to fetch updated dates')

            const data = await response.json()
            const registerEndedDate = data?.dates['register-end-date']
            if(isNotEmpty(registerEndedDate)) {
                const getTimeFromRegisterDate = new Date(registerEndedDate).getTime() + 86400000
                if(Date.now() > getTimeFromRegisterDate) setIsSignupOpen(false)
            }
        } catch (error) {
           console.log(error)
        }
    }

    const fetchCurrentDate = async () => {
        const getDateFromServer = await UtilsApi.getDateFromServer()
        if(isNotEmpty(getDateFromServer) && Number(getDateFromServer?.serverdate) > MAX_SIGNUP_DATE) setIsSignupAvailable(false)
    }

    React.useEffect(() => {
        fetchCurrentDate()
        fetchUpdatedDates()
    }, [])
    
    return (
        <div className={(className ? className : '')}>
            <h2 className="c-h2 u-bold u-center u-mg-bottom-xl">Découvrez le Programme "Objectif Employeur Pro-Vélo"</h2>
            <p className="u-mg-bottom-xl">Le Programme Objectif Employeur Pro-Vélo (OEPV) vise à accompagner les employeurs dans le développement d’une culture vélo. Des mesures incitatives permettent aux employeurs de déployer des équipements et services « Pro-Vélo » pour les déplacements de leurs collaborateurs, clients et fournisseurs. Pour en bénéficier, inscrivez-vous au Programme OEPV ! </p>
            {isSignupOpen &&
                        <div className="u-flex u-flex-center-hz u-mg-top-l">
                        {isSignupAvailable && <NavLink to="/inscription" className="c-btn c-btn--white u-pd-hz-xl u-mg-bottom-l">Inscription</NavLink>}
                        {!isSignupAvailable && 
                            <div className="u-mg-top-l u-center u-bg-secondary u-pd-l u-br-l">
                                <h3 className="c-h3 u-mg-bottom-s">Le programme OEPV<br/> n’est plus ouvert aux inscriptions. </h3>
                                <p>Dans les prochains mois, les employeurs pourront s’engager dans le Label Employeur Pro-Vélo. Nous vous invitons à vous abonner à notre <a href={process.env.REACT_APP_NEWSLETTER_URL} target={'_blank'} rel="noopener noreferrer"><u><b><span style={{color:'#66FFFF'}}>newsletter</span></b></u></a> pour être informé.</p>
                            </div>
                        }
                        </div>
            }
            {!isSignupOpen &&
            <div className="u-mg-top-l u-center u-bg-secondary u-pd-l u-br-l">
                <h3 className="c-h3 u-mg-bottom-s">Le programme OEPV<br/> n’est plus ouvert aux inscriptions. </h3>
                <p>Dans les prochains mois, les employeurs pourront s’engager dans le Label Employeur Pro-Vélo. Nous vous invitons à vous abonner à notre <a href={process.env.REACT_APP_NEWSLETTER_URL} target={'_blank'} rel="noopener noreferrer"><u><b><span style={{color:'#66FFFF'}}>newsletter</span></b></u></a> pour être informé.</p>
            </div>
}
        </div>
    )
}

export default RegisterBlock
