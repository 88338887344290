import React from 'react'
import { useHistory } from 'react-router-dom'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'

import saveBlob from 'utils/saveBlob'
import { fileHash } from 'utils/hash'
import { COMMITMENT_CHARTER_MEDIA_GENDER, getFullUrlCharter, getMediaUrlPrefix, isCommitmentCharterAlreadySigned } from 'utils/domain/user'
import uploadFile from 'services/uploadFile'
import * as UserApi from 'services/api/UserApi'
import dateService from 'services/date'
import { useAuth } from 'hooks/useAuth'
import { useAlert } from 'hooks/useAlert'
import { getKeywords } from 'components/auth/FormSignup'
import { privateRoutes } from 'router/routes'

import ProtectedLayout from 'layouts/Protected'
import Charter from 'components/Charter'
import FormElement from 'components/shared/FormElement'
import PDFCharter from 'assets/data/charte-engagement-vierge.pdf'
import { isNotEmpty } from 'utils/validation'

import './styles.scss'

const Commitment = () => {
    const history = useHistory()
    const { user, refreshUser } = useAuth()
    const { addAlert } = useAlert()
    const [accept, setAccept] = React.useState(false)
    const [loadedCharter, setLoadedCharter] = React.useState(false)
    const [isAcceptDisabled, setIsAcceptDisabled] = React.useState(true)    
    const [endedDate, setEndedDate] = React.useState(null)
    const [isCharteActivated, setCharteActivated] = React.useState(false)
    const isAuditEnded = endedDate && new Date() > new Date(endedDate)

    const fetchUpdatedDates = async () => {
        // Fetch distant document
        try {
            const response = await fetch(process.env.REACT_APP_UPDATED_DATES_URL)
            if (!response.ok) throw new Error('Failed to fetch updated dates')
            const data = await response.json()
            if(isNotEmpty(data)) setEndedDate(data?.dates?.['charte-end-date'])
            setCharteActivated(user.entity_data.isCharteActivated)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        fetchUpdatedDates()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setIsAcceptDisabled(!accept)
    }, [accept])

    const toggleAccept = () => {
        setAccept(accept => !accept)
    }


    const onLoadCharterSuccess = () => {
        setLoadedCharter(true)
    }

    const getModifiedCharter = async () => {
        const existingPdfBytes = await fetch(PDFCharter).then(res => res.arrayBuffer())
        
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const pages = pdfDoc.getPages()
        const signaturePage = pages[0]
    
        const fontStyle = {
            font: helveticaFont,
            color: rgb(0, 0, 0),
        }

        signaturePage.drawText('x', {
            x: 61,
            y: 91,
            size: 14,
            
        })

        signaturePage.drawText(user?.entity_data?.structureName, {
            x: 342,
            y: 58,
            size: 10,
            ...fontStyle
        })
        
        signaturePage.drawText(`le ${dateService.getCurrentDate('dd/MM/yyyy')}`, {
            x: 85,
            y: 55,
            size: 10,
            ...fontStyle
        })
        
        
        const pdfBytes = await pdfDoc.save()
        return {
            id: 'commitment-charter',
            extension: 'pdf',
            blob: new Blob([pdfBytes]),
        }
    }

    const acceptCharter = async () => {
        try {
            const charte_accept = dateService.getCurrentDate('yyyy-MM-dd HH:mm:ss')
            const modifiedCharter = await getModifiedCharter()
            const { fileToUpload: file } = await uploadFile('charte', {
                ...modifiedCharter,
                id: `commitment-charter-${user?.entity_id}-${new Date(charte_accept).getTime()}`
            })

            const media_id = file.id
            const media_url = `${getMediaUrlPrefix()}${media_id}.${file.extension}`
            const media_hash = await fileHash(modifiedCharter.blob)

            const documentToSave = {
                media_gender: COMMITMENT_CHARTER_MEDIA_GENDER,
                media_id,
                media_url,
                media_hash
            }

            const { getError } = await UserApi.updateUser({
                    last_row_hash: user?.row_hash,
                    status: user?.status,
                    entity_id: user?.entity_id,
                    entity_data: {
                        ...user?.entity_data,
                        documents: [
                            ...user?.entity_data?.documents || [],
                            documentToSave
                        ],
                        charte_accept
                    },
                    keywords: getKeywords({
                        ...user?.entity_data,
                        charte_accept,
                    })
            })
  
            if (getError()) throw getError()
            const dateFile = dateService.getCurrentDate('dd-MM-yyyy')
            saveBlob(modifiedCharter.blob, `charte-acces ${user?.entity_data?.structureName} ${dateFile}.pdf`)

            addAlert('success', 'Vous avez bien signé la charte d\'accès.')
            refreshUser()
            history.push(privateRoutes.home.path)
        } catch(error) {
            console.error(error)
            addAlert('error', typeof error === 'string' ? error : 'Une erreur est survenu lors de la signature de la charte d\'accès.')
            setAccept(false)
        }

    }

    return (
        <ProtectedLayout className="u-pd-vt-l u-pd-vt-xl@main">
            <div className="l-container">
                <h1 className="c-h1 u-primary u-bold u-center u-mg-bottom-l">Charte d'accès</h1>
                {isCommitmentCharterAlreadySigned(user) && (
                    <a
                        className="c-btn c-btn--secondary u-pd-hz-xl u-flex-self-end u-text-transform-none"
                        target="_blank" rel="noopener noreferrer"
                        href={getFullUrlCharter(user)}
                    >
                        Voir la charte d’accès signé le {dateService.formatDate(user?.entity_data?.charte_accept)}
                    </a>
                )}
                {!isCommitmentCharterAlreadySigned(user) && (
                    <>
                        <p className="u-mg-bottom-l">
                            Il est demandé à l’employeur de signer la charte d’accès du bénéficiaire pour poursuivre le parcours d’accompagnement. Cette charte formalise les engagements de l’employeur, notamment la mobilisation d’une personne référente et l’utilisation de la plateforme pour toute demande de prestation de service ou d’équipement. En signant la charte, l’employeur déclare s’inscrire dans une démarche de labellisation.
                        </p>
                        {isNotEmpty(endedDate) &&
                            <div className="u-flex u-flex-center-hz u-flex-center-vt u-mg-bottom-m u-bg-danger u-white u-pd-s u-fs-s">
                                <p className="u-center">La charte est à signer avant le :​</p>
                                <div className="u-mg-left-s u-bold">{dateService.formatDate(endedDate)}</div>
                            </div>
                        }
                        {!isCharteActivated && isAuditEnded &&
                            <div className="u-flex u-flex-center-hz u-flex-center-vt u-mg-bottom-m u-bg-danger u-white u-pd-s u-fs-s">
                                <p className="u-center">Vous n’avez pas signé la charte d’accès au programme dans les délais impartis. <br />
                                Il n’est plus possible de poursuivre le parcours du programme OEPV.​</p>
                            </div>
                        }
                        <p className="u-blue u-bold u-mg-bottom-l">
                            Merci de signer la charte d’accès en cochant la case en bas de page :
                        </p>
                        <div className="u-pd-m">
                            <div className="c-charter">
                                <Charter onLoadSuccess={onLoadCharterSuccess} />
                            </div>

                            {loadedCharter && (
                                <div className="signature-panel-form">
                                    <div className=" u-mg-m">
                                        <div className="u-flex u-blue u-bold u-fs-s u-pd-s u-mg-top-m">
                                            <FormElement
                                                value={accept}
                                                name="acceptCharter"
                                                type="checkbox"
                                                className=""
                                                classNameOption="u-flex-start-vt"
                                                onChange={toggleAccept}
                                                disabled={isAuditEnded && !isCharteActivated}
                                            />
                                            En cochant cette case, je certifie que mon établissement s’engage à respecter les termes de cette charte.
                                        </div>
                                        <div className="u-flex u-flex-dir-row u-flex-around u-pd-m infos-signature">

                                            <div className="u-flex">
                                                <span className="u-bold u-mg-right-xs">Date : </span>
                                                <span>le {dateService.getCurrentDate('dd/MM/y')}</span>
                                            </div>
                                            <div className="u-flex">
                                                <span className="u-bold u-mg-right-xs">Nom de l'employeur :</span>
                                                <span>{user.entity_data.structureName}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="u-flex u-flex-center-hz signature-panel-form-submit">
                                        <button 
                                            className="c-btn c-btn--secondary u-pd-hz-s u-mg-hz-m" 
                                            onClick={acceptCharter} 
                                            disabled={!isCharteActivated && (isAcceptDisabled || isAuditEnded)}>
                                            Confirmer la signature de la charte d'accès
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </ProtectedLayout>
    )
}

export default Commitment