import React from 'react'
import { string, func, bool, object, shape } from 'prop-types'
import FormElement from 'components/shared/FormElement'

const ConnexionInformation = ({
  formFields = {},
  formEvents = {},
  showLocalErrors = {},
  handleValidation = () => {},
  handleInputChange = () => {}
}) => {

  return (
    <div className='u-flex u-flex-dir-row u-mg-right-auto'>
      <div className='l-col-6'>
        <FormElement
          value={formFields.isConnexionAvailable ? true : false}
          options={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
          ]}
          classNameOption="c-btn u-mg-right-m input-hidden"
          classNameLabel="u-mg-vt-m"
          name="isConnexionAvailable"
          type="radio"
          label=""
          showErrors={showLocalErrors}
          onValidate={handleValidation}
          onChange={handleInputChange}
          disabled={!formEvents.editable}
        />
       </div>
    </div>
  )
}

ConnexionInformation.propTypes = {
  formFields: shape({
    structureName: string,
    structureSiret: string,
    structureAddress: string,
    structureCity: string,
    structurePostCode: string,
    structurePhoneNumber: string,
    emailConnexion: string,
    beneficiaryEmail: string,
    structureEmployeeQuantity: string,
    structureEmployeeCategory: string
  }),
  setFormFields: func,
  formEvents: object,
  showLocalErrors: bool,
  handleValidation: func,
  handleInputChange: func
}

export default ConnexionInformation
