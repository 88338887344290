import React from 'react'
import { NavLink } from 'react-router-dom'

import { arrayOf, bool, number, shape, string } from 'prop-types'
import { isNotEmpty } from 'utils/validation'
import dateService from 'services/date'

const Roadmap = ({ steps }) => {
    const [activeStepId, setActiveStepId] = React.useState(1)
    const [openStepId, setOpenStepId] = React.useState(activeStepId)
    const [isFirstDisplay, setIsFirstDisplay] = React.useState(true)
    const isAuditDoable = (process.env.REACT_APP_ENABLED_AUDIT_REQUEST === 'true')

    const STEP_CHARTER = 3
    const STEP_CATALOG = 4
    const STEP_AUDIT = 5
    const STEP_CERTIFICATION = 6

    const charterStepState = steps.find(step => step.index === STEP_CHARTER).state
    
    React.useEffect(() => {
        const currentActiveStep = steps.find(step => step.state === false)
        if (currentActiveStep) {
            // STEP_CATALOG could be not current step if one of following step has a true state
            // Eg : if the catalog step has a false state and the audit step has a true state, then the current step and the audit step
            if(currentActiveStep.index === STEP_CATALOG) {
                if(steps[STEP_AUDIT - 1].state) setActiveStepId(STEP_AUDIT)
            }

            if(currentActiveStep.index === STEP_AUDIT) {
                setActiveStepId(currentActiveStep.index - 1)
                setOpenStepId(currentActiveStep.index - 1)
            }

            // If the beneficiary is already at the certification step, but the audit is not active, then we go back to the previous step (audit)
            if(currentActiveStep.index === STEP_CERTIFICATION && !isAuditDoable) {
                setActiveStepId(currentActiveStep.index - 1)
                setOpenStepId(currentActiveStep.index - 1)
            } else {
                setActiveStepId(currentActiveStep.index)
                setOpenStepId(currentActiveStep.index)
            }
        }
    }, [steps])

    const getStepStateClassname = (stepId,isAuditRealized) => {
        const isDone = stepId < activeStepId
        const isVisible = stepId === openStepId
        const isActive = stepId === activeStepId 
        const hasCharterSigned = charterStepState
        const isDisabled = !isDone && !isVisible && !isActive
        console.log('openStepId',openStepId);
        console.log('activeStepId',activeStepId)
        
        const isDoneClassname = 'is-done'
        const isVisibleClassname = 'is-visible'
        const isActiveClassname = 'is-active'
        const hasCharterSignedClassname = 'has-charter-signed'
        const isDisabledClassname = 'is-disabled'
      
        // The audit step is active when the charter is already signed
        if(stepId === STEP_AUDIT && hasCharterSigned) {
            if(isDone) {
                return `
                ${isDoneClassname}
                ${isVisible ? isVisibleClassname : ''}
                ${isActive ? isActiveClassname : ''}
                ${hasCharterSigned ? hasCharterSignedClassname : ''}
                `
            }
            return `
              ${isActiveClassname}
              ${hasCharterSignedClassname}
              ${isVisible && isVisibleClassname}
            `
        }

        if(stepId === STEP_CERTIFICATION) {
            if(!isAuditDoable) return isDisabledClassname
        }

        if (isDisabled) return isDisabledClassname
      
        return `
            ${isDone ? isDoneClassname : ''}
            ${isVisible ? isVisibleClassname : ''}
            ${isActive ? isActiveClassname : ''}
            ${hasCharterSigned ? hasCharterSignedClassname : ''}
        `
        
      }


    const getStepDescription = (step, isEndedDatePassed) => {
        if(step.index === STEP_AUDIT && !isAuditDoable) return 'L\'audit n’est pas disponible avant février 2022.'
        return (
            <>
                {isEndedDatePassed && <p className="u-danger">{step.disabledDescription}</p>}
                {!isEndedDatePassed && step.description}
                {(step.linkText && step.link) &&
                    <NavLink to={step.link} className={'c-btn c-btn--secondary u-mg-top-m'} disabled={isEndedDatePassed}>
                        {step?.linkText}
                    </NavLink> 
                }
            </>
        )
    }

    return (
        <div className="c-roadmap u-mg-top-xl">
            <div className='c-roadmap__wrap u-pd-hz-l'>
                {steps.map((step) => {
                    console.log("isStepAvailable",step);
                    
                    // L'étape est bloquée pour le bénéficiaire si la date est dépassée OU si l'étape est bloquée par le backoffice
                    // Attention le backoffice peut bloquer une étape alors que la date n'est pas passée
                    // Le backoffice peut aussi déverrouiller une étape bloquée par la date
                    //let endedDatePlus1Day = new Date(step.endedDate).getTime()
                    //endedDatePlus1Day.setDate(endedDatePlus1Day.getDate() + 1);
                    //const isEndedDatePassed = step?.endedDate ? Date.now() > endedDatePlus1Day : false
                    const isEndedDatePassed = step?.endedDate ? Date.now() > new Date(step.endedDate).getTime() + 86400000 : false
                    const isStepAvailable = step?.isStepAvailable
                    console.log("isStepAvailable",isStepAvailable);
                    
                    let isStepBlockedForUser = false
                    if (isStepAvailable === false) {
                        isStepBlockedForUser = true
                    } else {
                        if (isEndedDatePassed) {
                            if (isStepAvailable === undefined) {
                                isStepBlockedForUser = true
                            } else if ((isStepAvailable === true)) {
                                isStepBlockedForUser = false
                            } else if (isStepAvailable === false ){
                                isStepBlockedForUser = true
                            }
                            // si pas renseigné par le bo alors isStepBlockedForUser = false
                        } else {
                            isStepBlockedForUser = false
                        }
                    }
                    console.log(">>>",step,isEndedDatePassed,isStepAvailable);
                    return (
                        <div className={`c-roadmap__step u-pd-top-xxl ${getStepStateClassname(step.index, step.isAuditRealized)}`} key={step.index}>
                            <div className="c-roadmap__date"><em>{step.date}</em></div>
                            <div className="c-roadmap__head">
                                <div className="c-roadmap__title" 
                                    tabIndex="0"
                                    role="button"
                                    aria-label="Voir la description de l'étape"
                                    onClick={() => {
                                        setOpenStepId(step.index)
                                        isFirstDisplay && setIsFirstDisplay(false)
                                    }}
                                    onKeyDown={(event) => {
                                        // space or enter
                                        if (event.keyCode === 32 || event.keyCode === 13) {
                                            setOpenStepId(step.index)
                                            isFirstDisplay && setIsFirstDisplay(false)
                                        }
                                    }}
                                > 
                                    <div className="c-roadmap__index">{step.index}</div>
                                    <h2 className="c-h4 c-h2__title"><span>{step?.title}</span></h2>
                                </div> 
                            </div>
    
                            <div className={`c-roadmap__description u-pd-m ${getStepStateClassname(step.index, step.isAuditRealized)}`}>
                                { getStepDescription(step, isStepBlockedForUser) }
                                {isNotEmpty(step.endedDate) &&
                                    <div className="u-mg-top-s u-bg-danger u-white u-pd-s u-fs-xxs">
                                        <p className="u-center">Date limite pour <br />{step?.dateText ?? "pour réaliser l'étape"} :</p>
                                        <div className="u-flex u-flex-center-hz">
                                            <div className="u-center ">{dateService.formatDate(step.endedDate)}</div>
                                        </div>
                                    </div>
    }
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

Roadmap.propTypes = {
    steps: arrayOf(
        shape({
            id: number,
            title: string,
            date: string,
            linkText: string, 
            description: string,
            state: bool,
            link: string, 
            endedDate: string
        })
    )
}

export default Roadmap