import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { privateRoutes } from 'router/routes'

import * as CatalogApi from 'services/api/CatalogApi'
import * as UtilsApi from 'services/api/UtilsApi'
import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import { useAuth } from 'hooks/useAuth'
import { getValidPassword } from 'utils/functions'
import { checkIsBackOfficeLeadSupervisor } from 'utils/domain/user'
import { equipmentProvider as EQUIPMENT_PROVIDER_CONST, GENDER_PROVIDER_VALUE } from 'utils/domain/provider'
import { scrollToTop } from 'utils/scrollTo'
import { isNotEmpty } from 'utils/validation'
import { isEmpty } from 'lodash'

import BackOfficeLayout from 'layouts/back-office'
import { getKeywords } from 'components/auth/FormSignup'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import Card from 'components/platform/Card'
import EquipmentProvidersServices from './EquipmentProviderServices'
import EquipmentProviderAdditionalServices from './EquipmentProviderAdditionalServices'
import FranceSupply from './FranceSupply'
import DomTomSupply from './DomTomSupply'
import FranceInstallation from './FranceInstallation'
import DomTomInstallation from './DomTomInstallation'
import EquipmentProviderInformations from './EquipmentProviderInformations'
import httpService, { API_BASE_URL_PROD, API_BASE_URL_TEST } from 'services/http'
import ConnexionInformation from './ConnexionInformation'

const defaultFormEvents = {
  ready: true,
  editable: false
}

const EquipmentsProviderDetail = () => {

  const [ isCreation, setIsCreation ] = React.useState(false)
  const [ equipmentProvider, setEquipmentProvider ] = React.useState()

  // form states
  const [formFields, setFormFields] = React.useState()
  const [formEvents, setFormEvents] = React.useState(defaultFormEvents)
  const [showLocalErrors, setShowLocalErrors] = React.useState(false)
  const [showGlobalError, setShowGlobalError] = React.useState(false)
  const [errors, setErrors] = React.useState([])

  const { addAlert } = useAlert()
  const { showModal } = useModal()
  const { user, logout } = useAuth()
  const { userId: equipmentProviderId } = useParams()
  const history = useHistory()

  const isBOLeadSupervisor = checkIsBackOfficeLeadSupervisor(user?.entity_data)

  React.useEffect(() => {
    setFormFields(prevFormFields => ({
      ...prevFormFields,
      ...getFormFieldsFromEquipmentProvider(equipmentProvider),
    }))
  }, [equipmentProvider])

  React.useEffect(() => {
    // creation
    if(isEmpty(equipmentProviderId)) {
      setIsCreation(true)
      setFormEvents((prevFormEvents) => ({ ...prevFormEvents, editable: true }))
    } else { // modification
      fetchRequest() 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentProviderId])

  const fetchRequest = async() => {
    try{
      const { res: entityResponse, getError } = await CatalogApi.getBackOfficeEntity(equipmentProviderId)
      if (getError()) throw getError()
      setEquipmentProvider({
        ...entityResponse,
        isActive: isNotEmpty(entityResponse?.entity_data?.active_entity) ? entityResponse.entity_data.active_entity : true // if active_entity doesn't exist or if active_entity is true, then provider is active
      })
    } catch(error) {
      addAlert('error', error?.message)
    }
  }

  // ------------------------------------------------------------------------------
  //              Form fields builder
  // ------------------------------------------------------------------------------
  const getFormFieldsFromEquipmentProvider = (equipmentProdiver) => {
    const equipmentProdiverData = equipmentProdiver?.entity_data
    return {
      // bloc "informations fournisseur d'équipement"
      structureName: equipmentProdiverData?.structureName || '',
      structureSiret: equipmentProdiverData?.structureSiret || '',
      contactLastname: equipmentProdiverData?.contactLastname || '',
      contactFirstname: equipmentProdiverData?.contactFirstname || '',
      structureAddress: equipmentProdiverData?.structureAddress || '',
      structureCity: equipmentProdiverData?.structureCity || '',
      structurePostCode: equipmentProdiverData?.structurePostCode || '',
      contactEmail: equipmentProdiverData?.contactEmail || '',
      connexionEmail: equipmentProdiverData?.mail || '',
      structurePhonenumber: equipmentProdiverData?.structurePhonenumber || '',
      website: equipmentProdiverData?.website || '',

      // bloc "Services proposés"
      bikeHitch: equipmentProdiverData?.bike_hitch === 'Oui',
      bikeShelter: equipmentProdiverData?.bike_shelter === 'Oui',
      bikeSecureStorage: equipmentProdiverData?.bike_secure_storage === 'Oui',
      bikePumps: equipmentProdiverData?.bike_pumps === 'Oui',
      bikeToolkit: equipmentProdiverData?.bike_toolkit === 'Oui',
      lockers: equipmentProdiverData?.lockers === 'Oui',

      // 4 checkbox blocks on bottom
      franceSupply: equipmentProdiverData?.france_supply === 'Oui',
      domTomSupply: equipmentProdiverData?.dom_tom_supply === 'Oui',
      franceInstallation: equipmentProdiverData?.france_installation === 'Oui',
      domTomInstallation: equipmentProdiverData?.dom_tom_installation === 'Oui',

      isConnexionAvailable: equipmentProdiverData?.isConnexionAvailable ?? true
    }
  }

  const handleValidation = React.useCallback((name, errs) => {
    setErrors((s) => {
        const cleanErrors = [...s.filter((e) => e.origin !== name)]
        return [
            ...cleanErrors,
            ...errs
        ]
    })
  }, [])

  const handleInputChange = (event) => {
    const target = event.target
    const name = target.name

    let value = null
    switch(target.type) {
      case 'checkbox':
        value = target.checked
        break
      case 'file':
        value = target
        break
      case 'radio':
        value = target.value === 'true' ? true : false
        break
      default: 
        value = target.value
    }

    setFormFields({
      ...formFields,
      [name]: value
    })
  }

  const handleConnexionEmailChange = (event) => {
    setFormFields({
        ...formFields,
        contactEmail: event.target.value,
        connexionEmail: event.target.value
    })
  }

  const showCancelationModal = (event) => {
    event.preventDefault()
    showModal(
        ModalConfirmation,
        {
            title: 'Êtes-vous sûr(e) de vouloir annuler ?',
            body: 'En cliquant sur "confirmer" vous serez redirigé(e) sur la liste des fournisseurs d\'équipement.',
            validate: backToEquipmentProviderList,
            closeAfterValidation: true
        }
    )
  }

  const backToEquipmentProviderList = () => {
    history.push({
      pathname: privateRoutes.backOffice.providersAdministration.path
    })
  }

  const showValidationModal = (event) => {
    event.preventDefault()

    const titleMotal = isCreation ? 'Êtes-vous sûr(e) de vouloir créer ce fournisseur ?' : 'Êtes-vous sûr(e) de vouloir modifier la fiche ?'
    const bodyModal = isCreation ? 'En cliquant sur "confirmer" vous enregistrez ce fournisseur.' : 'En cliquant sur "confirmer" vous modifiez les informations du fournisseur.'

    showModal(
        ModalConfirmation,
        {
            title: titleMotal,
            body: bodyModal,
            validate: handleSubmitValidation,
            closeAfterValidation: true
        }
    )
  }

  // ------------------------------------------------------------------------------
  //              Form submit method
  // ------------------------------------------------------------------------------
  const handleSubmitValidation = async () => {
    // Validate inputs
    const isFormReady = errors.length === 0
    if (!isFormReady) {
      setShowLocalErrors(true)
      setShowGlobalError(true)
      addAlert('error', 'Erreur(s) dans le formulaire.')
      scrollToTop()
      return
    }
    try {
      // Check if address is correct
      console.info('Coordinates have changed')
      const coordinates = await UtilsApi.getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)
      if (isNotEmpty(coordinates)) {
      
        if(!formFields.bikeHitch && !formFields.bikeShelter && !formFields.bikeSecureStorage && !formFields.bikePumps
          && !formFields.bikeToolkit && !formFields.lockers) {
            throw new Error('Au moins un service doit être attaché à ce fournisseur.')
        }
        const isActivePartipant = isCreation ? true : equipmentProvider?.isActive
        isCreation ? await createEquipmentProvider(coordinates) : await updateEquipmentProvider(isActivePartipant, coordinates) 
      } 
      else {
        const newErrors = {
          origin: 'structurePostCode',
          rule: 'structurePostCode-notFound',
          message: 'Le code postal n\'existe pas.'
        }
        setErrors((prevErrors) => [...prevErrors, newErrors])
        throw new Error('Adresse non reconnue, code postal inexistant.')
      }
    } catch (error) {
      addAlert('error', error?.message)
      setShowGlobalError(true)
      setShowLocalErrors(true)
      setFormEvents({ editable: true, ready: false })
    }
  }

  const checkEmail = async () => {
    // Check email
    const { res } = await CatalogApi.validateProviderEmail(formFields?.connexionEmail, GENDER_PROVIDER_VALUE)
    if(res?.error) {
      console.error(res.error.message)
      throw new Error('L\'e-mail que vous renseignez est déjà utilisé.')
    }
    return res
  }
  

  // ------------------------------------------------------------------------------
  //              Call API : create provider
  // ------------------------------------------------------------------------------
  const createEquipmentProvider = async (coordinates) => {
    
    // Create provider
    const providerToCreate = await buildCreateEquipmentProviderPayload(coordinates)

    // TODO: prevent saving token sent by the signUp route
    // request options
    const createEntityRoute = 'oepvapi/entity'
    const createEntityUrl = process.env.REACT_APP_MODE === 'production'
      ? API_BASE_URL_PROD + createEntityRoute
      : API_BASE_URL_TEST + createEntityRoute
    const options = {
      method: 'POST',
      body: JSON.stringify(providerToCreate),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Version': '1.0.0',
        'Authorization': `Bearer ${await httpService.getToken({ logout, addAlert })}`
      }
    }


    try {
      const response = await fetch(createEntityUrl, options)

      if(response.ok) {
        addAlert('success', 'Fournisseur enregistré.')
        backToEquipmentProviderList()
      }

      const error = await response.json()
      if (error?.code) throw error
    } catch (error) {

      if (error.code === 'NotAuthorizedException') {
        addAlert('error', 'Vous n\'êtes pas autorisé à créer un fournisseur')
      } else if (error.code === 'TooManyRequestsException') {
        addAlert('error', 'Ce fournisseur existe déjà')
      } else if (error.code === 'ServicesValueException') {
        addAlert('error', 'Vous devez sélectionner au moins un service pour enregistrer le fournisseur')
      } else if (error.code === 'DuplicatesValueException') {
        addAlert('error', 'Le SIRET est déjà enregistré pour un autre fournisseur')
      } else {
        addAlert('error', 'Une erreur s\'est produite lors de la création d\'un fournisseur')
      }

      setFormEvents({ ready: true, editable: true })
    }  
  }

  const buildCreateEquipmentProviderPayload = async (coordinates) => {
    const mailInfos = await checkEmail()
    const entityId = mailInfos?.entity_id
    const code = mailInfos?.code

    const entity_data = {
      // bloc "informations fournisseur d'équipement"
      structureName: formFields.structureName,
      structureSiret: formFields.structureSiret,
      contactLastname: formFields.contactLastname,
      contactFirstname: formFields.contactFirstname,
      structureAddress: formFields.structureAddress,
      structureCity: formFields.structureCity,
      structurePostCode: formFields.structurePostCode,
      structurePhonenumber: formFields.structurePhonenumber,
      website: formFields.website,
      longitude: coordinates[0],
      latitude: coordinates[1],
      mail: formFields.connexionEmail,
      contactEmail: formFields.contactEmail,
      active_entity: true,
      
      // bloc "Services proposés"
      bike_hitch: formFields.bikeHitch ? 'Oui' : 'Non',
      bike_shelter: formFields.bikeShelter ? 'Oui' : 'Non',
      bike_secure_storage: formFields.bikeSecureStorage ? 'Oui' : 'Non',
      bike_pumps: formFields.bikePumps ? 'Oui' : 'Non',
      bike_toolkit: formFields.bikeToolkit ? 'Oui' : 'Non',
      lockers: formFields.lockers ? 'Oui' : 'Non',

      // 4 checkbox blocks on bottom
      france_supply: formFields.franceSupply ? 'Oui' : 'Non',
      dom_tom_supply: formFields.domTomSupply ? 'Oui' : 'Non',
      installation: formFields.franceInstallation || formFields.domTomInstallation ? 'Oui' : 'Non',
      france_installation: formFields.franceInstallation ? 'Oui' : 'Non',
      dom_tom_installation: formFields.domTomInstallation ? 'Oui' : 'Non',
  
      // properties required by the API
      gender: GENDER_PROVIDER_VALUE,
      services: EQUIPMENT_PROVIDER_CONST.serviceId,
      siret: formFields.structureSiret,

      isConnexionAvailable: formFields.isConnexionAvailable
    }

    return {
      entity_id: entityId,
      code: code,
      password: getValidPassword(),
      keywords: getKeywords(formFields),
      entity_data
    }
  }

  // ------------------------------------------------------------------------------
  //              Call API : update provider
  // ------------------------------------------------------------------------------
  const updateEquipmentProvider = async (newActiveStatus, coordinates) => {
    try {
      const payload = buildUpdateEquipmentProviderPayload(newActiveStatus, coordinates)
      const { getError } = await CatalogApi.editBackOfficeEntity(payload) // call API to update beneficiary
      if(getError()) throw getError()
      fetchRequest()
      addAlert('success', 'Modification enregistrée')
      setFormEvents({ ready: true, editable: false })
      setShowGlobalError(false)
      setShowLocalErrors(false)
      scrollToTop()
    } catch (error) {
      addAlert('error', error?.message)
      setShowGlobalError(true)
      setShowLocalErrors(true)
      setFormEvents({ editable: true, ready: false })
    }
  }

  const buildUpdateEquipmentProviderPayload = (isActiveStatus, coordinates) => {
    const entity_data = {
      // bloc "informations fournisseur d'équipement"
      structureName: formFields.structureName,
      structureSiret: formFields.structureSiret,
      contactLastname: formFields.contactLastname,
      contactFirstname: formFields.contactFirstname,
      structureAddress: formFields.structureAddress,
      structureCity: formFields.structureCity,
      structurePostCode: formFields.structurePostCode,
      structurePhonenumber: formFields.structurePhonenumber,
      website: formFields.website,
      longitude: coordinates[0],
      latitude: coordinates[1],
      mail: formFields.connexionEmail,
      contactEmail: formFields.contactEmail,
      active_entity: isActiveStatus, // if the provider is active or not (warning, if the user is inactive, he stays as auditor or scoping meeting provider)

      // bloc "Services proposés"
      bike_hitch: formFields.bikeHitch ? 'Oui' : 'Non',
      bike_shelter: formFields.bikeShelter ? 'Oui' : 'Non',
      bike_secure_storage: formFields.bikeSecureStorage ? 'Oui' : 'Non',
      bike_pumps: formFields.bikePumps ? 'Oui' : 'Non',
      bike_toolkit: formFields.bikeToolkit ? 'Oui' : 'Non',
      lockers: formFields.lockers ? 'Oui' : 'Non',

      // 4 checkbox blocks on bottom
      france_supply: formFields.franceSupply ? 'Oui' : 'Non',
      dom_tom_supply: formFields.domTomSupply ? 'Oui' : 'Non',
      installation: formFields.franceInstallation || formFields.domTomInstallation ? 'Oui' : 'Non',
      france_installation: formFields.franceInstallation ? 'Oui' : 'Non',
      dom_tom_installation: formFields.domTomInstallation ? 'Oui' : 'Non',
      isConnexionAvailable: formFields.isConnexionAvailable,
  
      // properties required by the API
      gender: GENDER_PROVIDER_VALUE,
      services: EQUIPMENT_PROVIDER_CONST.serviceId,
      siret: formFields.structureSiret
    }

    return {
        entity_id: equipmentProvider.entity_id,
        status: equipmentProvider.status,
        last_row_hash: equipmentProvider.row_hash,
        keywords: getKeywords(equipmentProvider),
        entity_data
    }
  }
  
  const validationBtnLabel = React.useMemo(() => {
    if(formEvents?.editable) {
      if(isCreation) return 'Valider le fournisseur'
      return 'Valider les modifications'
    }
    
    if(equipmentProvider?.isActive) return 'Désactiver la fiche'
    if(!equipmentProvider?.isActive) return 'Réactiver la fiche'
  }, [formEvents?.editable, equipmentProvider?.isActive, isCreation])

  const toggleEditableForm = () => {
    setFormEvents(prevFormEvents => ({
        ...prevFormEvents,
        editable: !formEvents.editable
    }))
  }

  const showDesactivationModal = () => {
    const titleAction = equipmentProvider?.isActive ? 'désactiver' : 'réactiver'
    const coordinates = [ equipmentProvider.entity_data.longitude, equipmentProvider.entity_data.latitude]
    showModal(
        ModalConfirmation,
        {
            title: `Êtes-vous sûr(e) de vouloir ${titleAction} la fiche ?`,
            body: 'Attention, des dossiers en cours peuvent être impactés par ces modifications. Vous devez en informer les bénéficiaires concernés.',
            validate: () => updateEquipmentProvider(!equipmentProvider?.isActive, coordinates),
            closeAfterValidation: true
        }
    )
  }

  return (
    <BackOfficeLayout>
        <form>
          <div>
            { !isCreation && <h1 className="c-h1 u-inline-block u-mg-right-xl u-mg-bottom-l u-bold u-primary">Fiche de {equipmentProvider?.entity_data?.structureName}</h1>}
            { (isCreation && isBOLeadSupervisor) && <h1 className="c-h1 u-inline-block u-mg-right-xl u-mg-bottom-l u-bold u-primary">Créer un fournisseur d'équipement</h1>}
            { (!isCreation && isBOLeadSupervisor) && <span className={`u-bold c-h3 ${equipmentProvider?.isActive ? 'u-success' : 'u-danger'}`}>{equipmentProvider?.isActive ? 'Actif' : 'Inactif'}</span> }
            { showGlobalError && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>}

            <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
              <Card title="Informations fournisseur" className="l-col-7">
                <EquipmentProviderInformations
                  formFields={formFields}
                  formEvents={formEvents}
                  showLocalErrors={showLocalErrors}
                  handleValidation={handleValidation}
                  handleInputChange={handleInputChange}
                  handleConnexionEmailChange={handleConnexionEmailChange}
                  isCreation={isCreation}
                />
              </Card>
              <Card title="Services proposés" className="l-col-5">
                <div className="u-flex">
                  <EquipmentProvidersServices 
                    formFields={formFields}
                    formEvents={formEvents}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                  />
                  <EquipmentProviderAdditionalServices 
                    formFields={formFields}
                    formEvents={formEvents}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </Card>
            </div >
            <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
              <Card className="l-col-6" title="Fourniture">
                <div className='u-flex u-flex-dir-row u-flex-around'>
                  <FranceSupply
                    formFields={formFields}
                    formEvents={formEvents}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                  />
                  <DomTomSupply
                    formFields={formFields}
                    formEvents={formEvents}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </Card>
              <Card className="l-col-6" title="Pose">
                <div className='u-flex u-flex-dir-row u-flex-around'>
                  <FranceInstallation
                    formFields={formFields}
                    formEvents={formEvents}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                  />
                  <DomTomInstallation
                    formFields={formFields}
                    formEvents={formEvents}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </Card>
              </div><div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
            <Card className="l-col-6" title="Connexion au profil">
              <ConnexionInformation
                formFields={formFields}
                formEvents={formEvents}
                showLocalErrors={showLocalErrors}
                handleValidation={handleValidation}
                handleInputChange={handleInputChange}
              />
            </Card>
            </div>
            {isBOLeadSupervisor && <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main u-mg-top-l">
              { formEvents?.editable &&
                <>
                  <button
                    type="button"
                    className="c-btn c-btn--white-bordered c-btn--m u-pd-hz-l u-mg-bottom-xl"
                    style={{ width: '100%', maxWidth: '460px' }}
                    onClick={showCancelationModal}
                  >
                    Annuler
                  </button>
                  <div className="u-flex u-flex-center-hz u-pd-hz-l" style={{ minWidth: '250px' }}>
                    <button
                      type="submit"
                      className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                      style={{ width: '100%', maxWidth: '460px' }}
                      onClick={showValidationModal}
                    >
                      {validationBtnLabel}
                    </button>
                  </div>
                </>
              }
              { !formEvents?.editable && 
                <>
                  <button
                    type="button"
                    className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                    style={{ width: '100%', maxWidth: '300px' }}
                    onClick={toggleEditableForm}
                  >
                    Modifier
                  </button>
                  <button
                    type="button"
                    className="c-btn c-btn--danger c-btn--m u-pd-hz-l u-mg-bottom-xl u-mg-left-s"
                    style={{ width: '100%', maxWidth: '300px' }}
                    onClick={showDesactivationModal}
                  >
                    {validationBtnLabel}
                  </button>
                </>
              }
          </div>}
        </div>
      </form>
    </BackOfficeLayout>
  )
}

export default EquipmentsProviderDetail
