import React from 'react'
import { NavLink } from 'react-router-dom'

import PublicLayout from 'layouts/Public'
import FormSignup from 'components/auth/FormSignup'
import { isNotEmpty } from 'utils/validation'

const SignupPage = () => {
    const [isSignupOpen, setIsSignupOpen] = React.useState(true)

    const fetchUpdatedDates = async () => {
        // On récupère la date de fin d'inscription
        // Si la date actuelle est supérieure à la date de fin d'inscription, on ferme les inscriptions
        try {
            const response = await fetch(process.env.REACT_APP_UPDATED_DATES_URL)
            if (!response.ok) throw new Error('Failed to fetch updated dates')

            const data = await response.json()
            const registerEndedDate = data?.dates['register-end-date']
            if(isNotEmpty(registerEndedDate)) {
                const getTimeFromRegisterDate = new Date(registerEndedDate).getTime() + 86400000
                if(Date.now() > getTimeFromRegisterDate) setIsSignupOpen(false)
            }
        } catch (error) {
           console.log(error)
        }
    }

    React.useEffect(() => {
        fetchUpdatedDates()
    }, [])

    return (
        <PublicLayout showHeader={true} className="u-pd-hz-m u-pd-top-m u-pd-hz-0@main u-pd-top-xl@main">
            <div className="l-form-width u-mg-hz-auto">
                <h1 className="c-h1 u-bold u-primary u-center u-mg-bottom-m">Création de compte</h1>
                <div className="u-flex u-flex-center-hz u-mg-bottom-xl">
                    <NavLink to="/se-connecter" className="u-fs-xs u-underline">Déjà inscrit ?</NavLink>
                </div>
            </div>
            {isSignupOpen && <FormSignup />}
            {!isSignupOpen && <div className="u-mg-hz-xl u-strong u-bg-primary u-white u-center u-pd-l u-br-l">Les inscriptions sont terminées</div>}
        </PublicLayout>
    )
}

export default SignupPage
