import React, { useRef, useEffect, useCallback } from 'react'
import { func, object, shape, string } from 'prop-types'
import {
    Redirect,
    Route,
    useHistory
} from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { isAnAuthorizedUser, privateRoutes, publicRoutes } from 'router/routes'
import useBackOfficeRoutes from 'hooks/useBackOfficeRoutes'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ location, component: Component, pageProps, ...rest }) => {
    const { isLoggedIn, acceptedCGU, user, isABackOfficeUser } = useAuth()
    const authTimer = useRef()
    const history = useHistory()
    const { isCurrentRouteBackOffice } = useBackOfficeRoutes()

    const hasAcceptedCGU = useCallback(() => {
        if ([0, 1, 2, 9, 500].includes(user?.entity_data?.gender)) { // beneficiaries have already accepted
            return true
        }
        return acceptedCGU || location.pathname === privateRoutes.acceptCGU.path
    }, [user, acceptedCGU, location])

    useEffect(() => {
        if (!isLoggedIn) {
            // if the user is still not logged after 1000ms, redirect
            authTimer.current = setTimeout(() => {
                const redirectPath = isCurrentRouteBackOffice ? publicRoutes.backOffice.login.path : publicRoutes.login.path
                history.push(redirectPath)
            }, 1000)
        }
        else {
            if (!hasAcceptedCGU() && !isABackOfficeUser && location.pathname !== privateRoutes.acceptCGU.path) {
                // if the user is still not logged after 1000ms, redirect
                authTimer.current = setTimeout(() => {
                    history.push(privateRoutes.acceptCGU.path)
                }, 1000)
            }
            else {
                clearTimeout(authTimer.current)
            }
        }
    }, [isLoggedIn, acceptedCGU, hasAcceptedCGU, location, history, user, rest.path, isABackOfficeUser, isCurrentRouteBackOffice])

    if(isLoggedIn && !isAnAuthorizedUser(user, rest.path)) {
        return <Redirect to={publicRoutes.notFound.path} />
    }

    if(isLoggedIn && (hasAcceptedCGU() || isABackOfficeUser) && isAnAuthorizedUser(user, rest.path)) {
        return <Route {...rest} render={() => <Component {...pageProps} />}/>
    }

    return (
        <Route
            {...rest}
            render={() => (
                <div className="u-sticky-view">
                    <div className="c-main-content u-flex u-flex-center-hz u-flex-center-vt">
                        <div className="c-spinner"></div>
                    </div>
                </div>
            )}
        />
    )
}

PrivateRoute.propTypes = {
    location: shape({
        pathname: string
    }),
    component: func,
    pageProps: object
}

export default PrivateRoute