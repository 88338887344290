import React from 'react'
import { string } from 'prop-types'
import { NavLink } from 'react-router-dom'

import { privateRoutes } from 'router/routes'

import * as CatalogApi from 'services/api/CatalogApi'
import * as AuditApi from 'services/api/audit/auditApi'
import dateService from 'services/date'
import * as UtilsApi from 'services/api/UtilsApi'

import { useAlert } from 'hooks/useAlert'
import useBonus from 'hooks/useBonus'
import { useModal } from 'hooks/useModal'
import { useAuth } from 'hooks/useAuth'

import { COMMITMENT_CHARTER_MEDIA_GENDER, INACTIVE_STATUS_USER, checkIsBackOfficeLeadSupervisor, ACTIVE_STATUS_USER, checkIsBackOfficeManagementSupervisor, checkIsABackOfficeManagementAdvisor } from 'utils/domain/user'
import { getBeneficiary, getBeneficiaryScopingMeeting, getLastAutoDiagCompletedForDashboardAdmin } from 'utils/domain/admin'
import { employeeCategory, KBIS, PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE } from 'utils/domain/beneficiary'
import { INIT, FOLDER_PAID, REQUEST_VALIDATED, groupRequestsByGender } from 'utils/domain/request'
import { scrollToTop } from 'utils/scrollTo'
import { objectsHaveDiverged } from 'utils/functions'

import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import Card from 'components/platform/Card'
import Information from 'components/platform/Informations/Information'

import BeneficiaryInformations from './Informations'
import RequestsOverview from './RequestsOverview'
import RegularisationOverview from './RegularisationOverview'
import AuditCard from './Audit'
import { ScopingMeetingCard } from './ScopingMeeting'
import CertificationCard from './Certification'
import Payments from './Payments'

import './styles.scss'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import { hasAnAudit, CANCELED_STATUS, isCanceledAuditStatus } from 'utils/domain/audit'
import ModalConfirmation from 'components/shared/ModalConfirmation'
import { getEntityGender } from 'utils/domain/global'
import { getKeywords } from 'utils/domain/scopingMeetings'
import { isNotEmpty } from 'utils/validation'
import { AuditDownload } from './Audit/AuditDownload'
import SettingsCard from './Settings'
import ItemSetting from './ItemSetting'
import { RESERVED_STATUS } from 'utils/domain/audit'

const defaultFormEvents = {
    ready: true,
    editable: false
}

const BeneficiaryDetails = ({ beneficiaryId }) => {

    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [formFields, setFormFields] = React.useState({})
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)
    const [beneficiary, setBeneficiary] = React.useState()
    const [currentAudit, setCurrentAudit] = React.useState()
    const [lastCategoryEmployeeKnow, setLastCategoryEmployeeKnow] = React.useState({})
    const [endedDates, setEndedDates] = React.useState({})

    const { getRequestsWithBonus, getBonusByRequests } = useBonus({ isFromBackOffice: true })
    const { addAlert } = useAlert()
    const { showModal } = useModal()
    const { calculateBonus } = useBonus()
    const { user } = useAuth()

    const isBOLeadSupervisor = checkIsBackOfficeLeadSupervisor(user?.entity_data)
    const hasAutodiag = beneficiary?.autodiag?.full_media_url
    const hasCommitmentCharter = beneficiary?.commitmentCharter?.full_media_url
    const hasKbis = beneficiary?.kbis?.full_media_url
    const hasScopingMeetingDate = beneficiary?.scopingMeeting?.framing_meeting_date
    const hasScopingMeetingReport = beneficiary?.scopingMeeting?.framing_meeting_media_rapport_url?.full_media_url
    const requestsGroupedByGender = beneficiary?.requestsGroupedByGender || {}
    const requests = Object.values(requestsGroupedByGender).flat()
    const requestsNb = requests.filter(request => request.status !== INIT).length
    const regularisationFolders = beneficiary?.regularisationFolders ?? []
    
    const isAllowedToLock = user?.entity_data?.gender === 11 && user.entity_data?.permission === 0
    const {
        totalAmountEngaged,
        totalAmountValidated,
        availableBonus,
    } = getBonusByRequests({
        requests: [...requests, ...regularisationFolders],
        userEntityData: beneficiary?.entity_data,
        beneficiary: beneficiary
    })

    // BO can open a regularisation folder if one (at least) of the beneficiary's request is validated or paid, and if
    // there is enough remaining bonus.
    const hasEnoughRemainingBonus = beneficiary?.amount_refunded + totalAmountValidated < availableBonus
    const canOpenRegularisationFolder = hasEnoughRemainingBonus && requests.some(({ status }) => (status === REQUEST_VALIDATED) || (status === FOLDER_PAID)) && isBOLeadSupervisor

    const fetchUpdatedDates = async () => {
        // Fetch distant document
        try {
            const response = await fetch(process.env.REACT_APP_UPDATED_DATES_URL)
            if (!response.ok) throw new Error('Failed to fetch updated dates')
            const data = await response.json()
            if(isNotEmpty(data)) setEndedDates(data?.dates)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        fetchUpdatedDates()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        loadBeneficiaryDetails()
    }, [beneficiaryId]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setFormFields(prevFormFields => ({
            ...prevFormFields,
            ...getFormFieldsFromBeneficiary(beneficiary),
        }))
        setLastCategoryEmployeeKnow(employeeCategory.filter(cat => cat.value === beneficiary?.entity_data?.structureEmployeeCategory)?.[0] || '')
    }, [beneficiary])

    const loadBeneficiaryDetails = async () => {
        try {
            const lastAutoDiag = await getLastAutoDiagCompletedForDashboardAdmin(beneficiaryId)
            const scopingMeeting = await getBeneficiaryScopingMeeting(beneficiaryId)
            const beneficiaryResponse = await getBeneficiary(beneficiaryId)
            const { res: requests, getError: getRequestsError } = await CatalogApi.getBackOfficeRequestsByEntity(beneficiaryId)
            if (getRequestsError()) throw getRequestsError()

            const { res: audits, getError: getAuditError } = await AuditApi.getBackOfficeBeneficiaryAudit(beneficiaryId)
            if (getAuditError()) throw getAuditError()

            // A single audit can be performed by the beneficiary but the back-end return an array of one audit
            // First we removed all canceled audits from array
            const filteredAudits = Array.isArray(audits) && audits.filter(audit => audit.audit_status !== CANCELED_STATUS)
            const audit = filteredAudits ? filteredAudits[0] : {}

            // For the Audit block, we need the last current audit, no matter the status
            if (Array.isArray(audits)) setCurrentAudit(audits.find(audit => !isCanceledAuditStatus(audit.audit_status)))

            const kbis = beneficiaryResponse?.documents?.find(({ media_gender }) => media_gender === KBIS)
            const commitmentCharter = beneficiaryResponse?.documents?.find(({ media_gender }) => media_gender === COMMITMENT_CHARTER_MEDIA_GENDER)

            const requestsWithBonus = await getRequestsWithBonus(requests, beneficiaryResponse?.entity_data?.structureEmployeeQuantity)

            const { res: regularisationFoldersResponse, getError: getRegularisationFoldersError } = await CatalogApi.getRegularisationFolders(beneficiaryResponse?.entity_id)
            if (getRegularisationFoldersError()) throw getRegularisationFoldersError()

            // Adjust the regularisation folder structure to the request structure
            const mappedRegularisationFolders = (regularisationFoldersResponse ?? []).map(folder => ({
                genderLabel: 'Régularisation',
                request_fub_id: folder?.request_fub_id,
                create_date: folder?.create_date,
                amount: '-',
                bonus: +folder?.regularization_data?.amount,
                realBonus: +folder?.regularization_data?.real_amount_to_refund,
                real_amount_to_refund: +folder?.regularization_data?.real_amount_to_refund,
                statusLabel: folder?.status === 0 ? 'Validé' : 'Payé',
                modify_date: folder?.status === 0 ? '-' : folder?.regularization_data?.refund_date,
                fub_user_name: folder?.fub_user_name,
                comment: folder?.regularization_data?.comment,
                regularisation_status: folder?.status,
                // eq of validated request
                status: folder?.status === 0 ? REQUEST_VALIDATED : 0
            }))

            setBeneficiary({
                ...beneficiaryResponse,
                audit: {
                    ...audit,
                    hasAnAudit: hasAnAudit(audit),
                },
                requestsGroupedByGender: groupRequestsByGender(requestsWithBonus),
                regularisationFolders: mappedRegularisationFolders,
                kbis,
                commitmentCharter,
                scopingMeeting,
                autodiag: lastAutoDiag?.diag_data?.documents?.[0],
                isActive: beneficiaryResponse?.status !== INACTIVE_STATUS_USER
            })

        } catch (error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }


    const getFormFieldsFromBeneficiary = (beneficiary) => {
        const beneficiaryData = beneficiary?.entity_data

        return {
            structureName: beneficiaryData?.structureName || '',
            entityGender: getEntityGender(beneficiaryData?.gender)?.label || '',
            structureSiret: beneficiaryData?.structureSiret || '',
            structureAddress: beneficiaryData?.structureAddress || '',
            structureCity: beneficiaryData?.structureCity || '',
            structurePostCode: beneficiaryData?.structurePostCode || '',
            structurePhoneNumber: beneficiaryData?.structurePhonenumber || '',
            createDate: (beneficiary?.create_date && dateService.formatDate(beneficiary?.create_date)) || '',
            accountSupervisor: `${beneficiaryData?.contactLastname ? beneficiary.contactLastname : ''} ${beneficiary?.contactFirstname ? beneficiary.contactFirstname : ''}` || '',
            contactPhonenumber: beneficiaryData?.contactPhonenumber || '',
            mail: beneficiaryData?.mail || '',
            contactEmail: beneficiaryData?.contactEmail || '',
            additionalDocument: beneficiaryData?.['documents']?.filter(el => el.media_gender === 'PJ')[0] || '',
            structureEmployeeQuantity: beneficiaryData?.structureEmployeeQuantity || '',
            structureEmployeeCategory: employeeCategory.filter(cat => cat.value === beneficiaryData?.structureEmployeeCategory)[0] || '',
            isAdditionalDocumentDeleted: false,
        }
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const buildBeneficiaryPayload = (specificStatus) => {

        if(formFields.isAdditionalDocumentDeleted) {
            beneficiary.entity_data['documents'] = beneficiary.entity_data?.['documents']?.filter(el => el.media_gender !== 'PJ')
        }

        const entity_data = {
            ...beneficiary.entity_data,
            ...formFields
        }
        return {
            entity_id: beneficiary.entity_id,
            status: specificStatus ? specificStatus : beneficiary.status,
            last_row_hash: beneficiary.row_hash,
            keywords: getKeywords(beneficiary),
            entity_data
        }
    }

    const updateBeneficiary = async (payload, isScrollToTopActivated) => {
        const { getError } = await CatalogApi.editBackOfficeEntity(payload) // call API to update beneficiary
        if (getError()) throw getError()
        loadBeneficiaryDetails()
        addAlert('success', 'Modification enregistrée')
        setFormEvents(oldFormEvents => ({ ...oldFormEvents, ready: true }))
        isScrollToTopActivated && scrollToTop()
    }

    const handleSubmitValidation = async () => {
        // Validate inputs
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return
        }
        try {
            setShowGlobalError(false)
            setShowLocalErrors(false)
            setFormEvents({ editable: false, ready: false })

            // Check if address is correct
            // -------------
            // Recalculate coordinates if address has changed
            if (objectsHaveDiverged(beneficiary.entity_data, formFields, ['structureAddress', 'structurePostCode'])) {
                const coordinates = await UtilsApi.getCoordinatesFromAdress(formFields?.structureAddress, formFields?.structurePostCode)

                if (isNotEmpty(coordinates)) {
                    beneficiary.entity_data['latitude'] = coordinates[1]
                    beneficiary.entity_data['longitude'] = coordinates[0]
                } else {
                    const newErrors = {
                        origin: 'structurePostCode',
                        rule: 'structurePostCode-notFound',
                        message: 'Le code postal n\'existe pas.'
                    }
                    setErrors((prevErrors) => [...prevErrors, newErrors])
                    setShowGlobalError(true)
                    throw new Error('Adresse non reconnue, code postal inexistant')
                }
            }

            // Recalculate prime
            // -------------
            if (objectsHaveDiverged(beneficiary.entity_data, formFields, ['structurePostCode', 'structureEmployeeCategory'])) {
                const oldPrime = beneficiary.entity_data.prime
                const newPrime = calculateBonus(formFields?.structurePostCode, formFields?.structureEmployeeCategory)
                if (oldPrime !== newPrime) {
                    console.info(('Prime has changed'))
                    beneficiary.entity_data['prime'] = newPrime
                }
            }

            const payload = buildBeneficiaryPayload() // prepare beneficiary data
            updateBeneficiary(payload, true)

        } catch (error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
        }
    }

    const showValidationModal = (event) => {
        event.preventDefault()
        showModal(
            ModalConfirmation,
            {
                title: 'Êtes-vous sûr(e) de vouloir modifier la fiche ?',
                body: 'En cliquant sur "confirmer" vous modifiez les informations du bénéficiaire.',
                validate: handleSubmitValidation,
                closeAfterValidation: true
            }
        )
    }

    const cancelModifications = () => {
        toggleEditableForm()
        setFormFields(prevFormFields => ({
            ...prevFormFields,
            ...getFormFieldsFromBeneficiary(beneficiary),
        }))
    }

    const toggleEditableForm = () => {
        scrollToTop()
        setFormEvents(prevFormEvents => ({
            ...prevFormEvents,
            editable: !formEvents.editable
        }))
    }

    const showDesactivationModal = (event) => {
        event.preventDefault()
        const status = beneficiary?.isActive ? INACTIVE_STATUS_USER : ACTIVE_STATUS_USER
        const titleAction = beneficiary?.isActive ? 'désactiver' : 'réactiver'
        const bodyAction = beneficiary?.isActive ? 'désactivez' : 'réactivez'
        const payload = buildBeneficiaryPayload(status)
        showModal(
            ModalConfirmation,
            {
                title: `Êtes-vous sûr(e) de vouloir ${titleAction} la fiche ?`,
                body: `En cliquant sur "confirmer" vous ${bodyAction} la fiche du bénéficiaire.`,
                validate: () => updateBeneficiary(payload, true),
                closeAfterValidation: true
            }
        )
    }

    const isPrivateCompanyWithPublicVocation = beneficiary?.entity_data?.gender === PRIVATE_SECTOR_WITH_PUBLIC_VOCATION_VALUE

    const bonusStillAvailable = availableBonus - (totalAmountValidated + beneficiary?.amount_refunded)
    const bonusAmount = bonusStillAvailable <= 0 ? 0 : bonusStillAvailable

    const isAuditLocked = beneficiary?.audit?.audit_status === RESERVED_STATUS
console.log("isAllowedToLock",isAllowedToLock);

    return (
        <>
            {!beneficiary &&
                <div className="u-center u-pd-vt-xxl">
                    <div className="c-spinner"></div>
                </div>
            }
            {beneficiary &&
                <form>
                    <div>
                        <h1 className="c-h1 u-inline-block u-mg-right-xl u-mg-bottom-l u-bold u-primary">{beneficiary?.entity_data?.structureName}</h1>
                        {isBOLeadSupervisor && <span className={`u-bold c-h3 ${beneficiary?.isActive ? 'u-success' : 'u-danger'}`}>{beneficiary?.isActive ? 'Actif' : 'Inactif'}</span>}
                        {!isBOLeadSupervisor && <h2 className="u-bold c-h3 u-secondary u-mg-top-m u-mg-bottom-xl">{requestsNb} prestation(s)</h2>}
                        {showGlobalError && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur lors de la validation du formulaire.</p>}

                        {/* Overview informations */}
                        <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">

                            <BeneficiaryInformations
                                className="l-col-8"
                                setFormFields={setFormFields}
                                formFields={formFields}
                                formEvents={formEvents}
                                showLocalErrors={showLocalErrors}
                                handleValidation={handleValidation}
                                setLastCategoryEmployeeKnow={setLastCategoryEmployeeKnow}
                                lastCategoryEmployeeKnow={lastCategoryEmployeeKnow}
                            />

                            <div className="l-col-4">
                                <Card title="Prime">
                                    <Information
                                        className="u-mg-top-l u-mg-bottom-m"
                                        classNameValue="u-fs-m u-danger"
                                        label="Prime initiale"
                                        value={availableBonus ? formatNumberAndAddEurCurrency(availableBonus) : '-'}
                                        description="Montant de prime maximum auquel vous pouvez prétendre en fonction de l’effectif de votre structure et de sa localisation, toutes prestations confondues."
                                    />
                                    <Information
                                        className="u-mg-bottom-m"
                                        classNameValue="u-fs-m u-primary"
                                        label="Montant engagé non validé"
                                        value={totalAmountEngaged ? formatNumberAndAddEurCurrency(totalAmountEngaged) : '-'}
                                        description="Montant de prime correspondant aux devis que vous avez choisis, toutes prestations confondues, qui n’a pas encore été validé par le programme."
                                    />
                                    <Information
                                        className="u-mg-bottom-m"
                                        classNameValue="u-fs-m u-primary"
                                        label="Montant validé non remboursé"
                                        value={totalAmountValidated ? formatNumberAndAddEurCurrency(totalAmountValidated) : '-'}
                                        description="Montant de prime validé par le programme, en attente de paiement, toutes prestations confondues."
                                    />
                                    <Information
                                        className="u-mg-bottom-m"
                                        classNameValue="u-fs-m u-success"
                                        label="Montant remboursé"
                                        value={beneficiary?.amount_refunded ? formatNumberAndAddEurCurrency(beneficiary?.amount_refunded) : '-'}
                                        description="Montant de prime validé par le programme, déjà remboursé, toutes prestations confondues."
                                    />

                                    <Information
                                        className="u-mg-bottom-m"
                                        classNameValue="u-fs-m u-success"
                                        label="Prime disponible"
                                        value={formatNumberAndAddEurCurrency(bonusAmount)}
                                        description="Montant correspondant à la différence entre la Prime initiale et le Montant validé non remboursé ajouté au Montant remboursé. Attention: ce montant ne prend pas en compte le montant engagé non validé. Si le montant de prime disponible est égal à 0, vous pouvez toujours commander des prestations depuis la plateforme, mais vous ne percevrez plus de primes pour celles-ci."
                                    />

                                    {/* Regularisation */}
                                    {canOpenRegularisationFolder &&
                                        <div className="u-flex u-flex-center-hz u-pd-s u-mg-top-m">
                                            <NavLink
                                                to={privateRoutes.backOffice.beneficiaryRegularisation.navLink({ beneficiaryId })}
                                                className="c-btn c-btn--danger c-btn--s u-pd-hz-l u-pd-vt-s"
                                            >
                                                Créer un dossier de régularisation
                                            </NavLink>
                                        </div>
                                    }
                                </Card>
                                <AuditDownload currentAudit={currentAudit} />
                            </div>
                        </div>             

                        {/* Documents */}
                        <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
                            <Card className="l-col-2" title={isPrivateCompanyWithPublicVocation ? 'Sirene' : 'Kbis'}>
                                {hasKbis && (
                                    <InformationToVisualize
                                        label={isPrivateCompanyWithPublicVocation ? 'Consulter le Sirene de l\'établissement' : 'Consulter le Kbis de l\'établissement'}
                                        url={beneficiary.kbis.full_media_url}
                                        iconColor="green"
                                        asCol
                                    />
                                )}
                                {!hasKbis && (
                                    <div className="u-grey90 u-fs-xs u-mg-top-s">
                                        Le {isPrivateCompanyWithPublicVocation ? 'Sirene' : 'Kbis'} n'a pas été envoyé.
                                    </div>
                                )}
                            </Card>
                            <Card className="l-col-2" title="Auto-diagnostic">
                                {hasAutodiag && (
                                    <InformationToVisualize
                                        label="Consulter le rapport"
                                        url={beneficiary.autodiag.full_media_url}
                                        iconColor="green"
                                        asCol
                                    />
                                )}
                                {!hasAutodiag && (
                                    <div className="u-grey90 u-fs-xs u-mg-top-s">
                                        L'auto-diagnostic n'a pas été complété.
                                    </div>
                                )}

                                {isAllowedToLock &&
                                    <ItemSetting beneficiary={beneficiary} updateBeneficiary={updateBeneficiary} endedDates={endedDates} type="autodiag" />
                                }
                                
                            </Card>

                            <Card className="l-col-4" title="Prestation réunion de cadrage">
                                <ScopingMeetingCard 
                                    scopingMeeting={beneficiary?.scopingMeeting}    
                                    isBOLeadSupervisor={isBOLeadSupervisor}
                                    hasScopingMeetingReport={hasScopingMeetingReport}
                                    hasScopingMeetingDate={hasScopingMeetingDate}
                                    loadBeneficiaryDetails={loadBeneficiaryDetails}
                                />
                                {isAllowedToLock &&
                                    <ItemSetting beneficiary={beneficiary} updateBeneficiary={updateBeneficiary} endedDates={endedDates} type="framing-meeting" />
                                }
                            </Card>

                            <Card className="l-col-4" title="Charte d’accès au catalogue">
                                {hasCommitmentCharter && (
                                    <>
                                        <InformationToVisualize
                                            label="Consulter la charte d'accès signée"
                                            url={beneficiary.commitmentCharter.full_media_url}
                                            iconColor="green"
                                            asCol
                                        />
                                        {isNotEmpty(beneficiary.entity_data?.charte_accept) &&
                                            <div className="u-grey90 u-fs-xs u-flex u-flex-center-hz">Charte signée le {dateService.formatDate(beneficiary.entity_data?.charte_accept)}</div>
                                        }
                                    </>
                                )}
                                {!hasCommitmentCharter && (
                                    <div className="u-grey90 u-fs-xs u-mg-top-s">
                                        La charte d'accès n'a pas été signée.
                                    </div>
                                )}
                                {isAllowedToLock &&
                                    <ItemSetting beneficiary={beneficiary} updateBeneficiary={updateBeneficiary} endedDates={endedDates} type="charte" />
                                }
                            </Card>
                        </div>

                        {!isBOLeadSupervisor &&
                            <>
                                <RequestsOverview beneficiary={beneficiary} />
                                <RegularisationOverview beneficiary={beneficiary} />
                            </>
                        }

                        <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
                            <Card title={`Audit ${isAuditLocked ? '(bloqué)' : ''}`} className="l-col-6">
                                <AuditCard
                                    audit={beneficiary?.audit}
                                    className="l-col-6"
                                    isBOLeadSupervisor={isBOLeadSupervisor}
                                    loadBeneficiaryDetails={loadBeneficiaryDetails}
                                />
                                {isAllowedToLock &&
                                    <ItemSetting beneficiary={beneficiary} updateBeneficiary={updateBeneficiary} endedDates={endedDates} type="audit" />
                                }
                            </Card>
                            {!isBOLeadSupervisor && <CertificationCard audit={beneficiary?.audit} className="l-col-6" />}
                        </div>

                        {isBOLeadSupervisor && <SettingsCard beneficiary={beneficiary} updateBeneficiary={updateBeneficiary} endedDates={endedDates} />}

                        {!isBOLeadSupervisor &&
                            <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">
                                <Payments requests={[...requests, ...regularisationFolders]} />
                            </div>
                        }
                    </div>
                    {isBOLeadSupervisor && <div className="u-flex u-flex-center-hz u-pd-top-l u-mg-negative-hz-m u-pd-hz-m u-mg-hz-0@main u-pd-hz-0@main u-mg-top-l">
                        {formEvents?.editable &&
                            <>
                                <button
                                    type="button"
                                    className="c-btn c-btn--white-bordered c-btn--m u-pd-hz-l u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '460px' }}
                                    onClick={cancelModifications}
                                >
                                    Annuler
                                </button>
                                <div className="u-flex u-flex-center-hz u-pd-hz-l" style={{ minWidth: '250px' }}>
                                    {formEvents.ready &&
                                        <button
                                            type="submit"
                                            className="c-btn c-btn--primary c-btn--m u-pd-hz-xl u-mg-bottom-xl"
                                            style={{ width: '100%', maxWidth: '460px' }}
                                            onClick={showValidationModal}
                                        >
                                            Valider les modifications
                                        </button>
                                    }
                                    {!formEvents.ready &&
                                        <div className="c-spinner"></div>
                                    }
                                </div>
                            </>
                        }
                        {!formEvents?.editable &&
                            <>
                                <button
                                    type="button"
                                    className="c-btn c-btn--primary c-btn--m u-pd-hz-l u-mg-bottom-xl"
                                    style={{ width: '100%', maxWidth: '300px' }}
                                    onClick={toggleEditableForm}
                                >
                                    Modifier
                                </button>
                                <button
                                    type="button"
                                    className="c-btn c-btn--danger c-btn--m u-pd-hz-l u-mg-bottom-xl u-mg-left-s"
                                    style={{ width: '100%', maxWidth: '300px' }}
                                    onClick={showDesactivationModal}
                                >
                                    {beneficiary?.isActive ? 'Désactiver la fiche' : 'Réactiver la fiche'}
                                </button>
                            </>
                        }
                    </div>}
                </form>
            }
        </>
    )
}

export default BeneficiaryDetails

BeneficiaryDetails.propTypes = {
    beneficiaryId: string,
}