import React from 'react'
import { objectOf, shape, string, func } from 'prop-types'

import Card from 'components/platform/Card'
import { getKeywords } from 'utils/domain/scopingMeetings'

import UnlockIcon from 'components/svg/Unlock'
import LockIcon from 'components/svg/Lock'
import { useAuth } from 'hooks/useAuth'

const SettingsCard = ({ beneficiary, updateBeneficiary, endedDates }) => {
    // De base, le bénéficiaire ne possède pas de valeur pour les settings. 
    // Pour définir la valeur de départ, on regarde si la date de fin est dépassée pour chaque setting
    // Si c'est le cas, on met la valeur "isActivated" à false, sinon à true
    // Une fois qu'un setting est enregistré dans l'entity_data, c'est cette valeur qui est utilisée
    const { user } = useAuth()
    const isAllowedToLock = user?.entity_data?.gender === 11 && user.entity_data?.permission === 0

    const { entity_data } = beneficiary
    const [isLoading, setIsLoading] = React.useState(false)

    const supportEndedDate = endedDates?.['requests-CA-end-date']
    const isSupportEnded = supportEndedDate && Date.now() > new Date(supportEndedDate).getTime() + 86400000
    
    const equipmentEndedDate = endedDates?.['requests-equipment-end-date']
    const isEquipmentEnded = equipmentEndedDate && Date.now() > new Date(equipmentEndedDate).getTime() + 86400000

    const mobilityEndedDate = endedDates?.['requests-sem-end-date']
    const isMobilityEnded = mobilityEndedDate && Date.now() > new Date(mobilityEndedDate).getTime() + 86400000

    const technicalEndedDate = endedDates?.['requests-ST-end-date']
    const isTechnicalEnded = technicalEndedDate && Date.now() > new Date(technicalEndedDate).getTime() + 86400000

    // Equipement => isEquipmentActivated
    // Mobilité - SEM => isMobilityActivated
    // Technique - ST => isTechnicalActivated
    // Accompagnement - C&A => isSupportActivated
    const { 
        isEquipmentActivated = isEquipmentEnded ? false : true, 
        isMobilityActivated = isMobilityEnded ? false : true, 
        isTechnicalActivated = isTechnicalEnded ? false : true, 
        isSupportActivated = isSupportEnded ? false : true
      } = entity_data || {}

    const arrayOfSettings = [
        { name: 'Equipement', isActivated: isEquipmentActivated, id: 'isEquipmentActivated' },
        { name: 'Mobilité - SEM', isActivated: isMobilityActivated, id: 'isMobilityActivated' },
        { name: 'Technique - ST', isActivated: isTechnicalActivated, id: 'isTechnicalActivated' },
        { name: 'Accompagnement - C&A', isActivated: isSupportActivated, id: 'isSupportActivated' }
    ]

    const updateBeneficiarySettings = async (settingId, settingValue) => {
        setIsLoading(true)
        try {   
            const entity_data = {
                ...beneficiary.entity_data,
                [settingId]: !settingValue
            }
            const payload = {
                entity_id: beneficiary.entity_id,
                status: beneficiary.status,
                last_row_hash: beneficiary.row_hash,
                keywords: getKeywords(beneficiary),
                entity_data
            }
            await updateBeneficiary(payload, false)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }

    }

    return (
        <Card className="l-col-12" title="Activer/Désactiver les prestations">
            <p className="u-mg-bottom-m u-fs-xs">Au clic sur l'un des cadenas, vous pourrez activer ou désactiver la prestation pour le bénéficiaire</p>
            <div className="u-flex u-flex-between">
                {arrayOfSettings.map(setting => (
                    <div className="u-flex">
                        <p className="u-mg-right-s">{setting.name}</p> 
                        {isAllowedToLock && 
                            <button disabled={isLoading} type="button" onClick={() => updateBeneficiarySettings(setting.id, setting.isActivated)}>{setting.isActivated ? <UnlockIcon /> : <LockIcon />}</button>
                        }
                    </div>
                ))}
            </div>
        </Card>
    )
}

SettingsCard.propTypes = {
    beneficiary: shape({
        requestsGroupedByGender: objectOf(
            shape({
                request_id: string.isRequired,
                status: string.isRequired
            })
        )
    }),
    updateBeneficiary: func,
    endedDates: objectOf(string)
}


export default SettingsCard
