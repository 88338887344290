import React from 'react'
import { bool, object, func } from 'prop-types'

import dateService from 'services/date'
import * as CatalogApi from 'services/api/CatalogApi'

import { useAlert } from 'hooks/useAlert'
import InformationToVisualize from 'components/platform/Informations/InformationToVisualize'
import RowInformations from 'components/platform/Informations/RowInformations'
import FormElement from 'components/shared/FormElement'
import { scopingMeetingProvider } from 'utils/domain/provider'
import { isNotEmpty } from 'utils/validation'

const defaultFormFields = {
    provider: null
}

export const ScopingMeetingCard = ({
    scopingMeeting,
    hasScopingMeetingDate,
    isBOLeadSupervisor,
    hasScopingMeetingReport,
    loadBeneficiaryDetails
}) => {
    const [providerList, setProviderList] = React.useState([])

    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [errors, setErrors] = React.useState([])
    const { addAlert } = useAlert()

    const fetchEntities = async () => {
        try {   
            const activeEntities = true // param used to search only active providers
            const { res: entities, getError } = await CatalogApi.getEntities({ services: scopingMeetingProvider.serviceId, activeEntities })
            if (getError()) throw getError()
            
            // On pousse les entities dans un state : providerList
            if(isNotEmpty(entities)) 
                setProviderList(() => 
                    entities.map((item) => {
                        return {
                            value: item.entity_id,
                            label: item.entity_data.structureName,
                        }
                    }),
                )
        } catch (error) {
            addAlert('error', 'Une erreur est survenue lors de la récupération des prestataires')
        }
    }

    const cancelScopingMeeting = async () => { 
        try {   
            const editedScopingMeeting = {
                entity_id: scopingMeeting?.entity_id,
            }
            const { getError } = await CatalogApi.cancelBackOfficeScopingMeetingEntity(editedScopingMeeting)
            if (getError()) throw getError()
            addAlert('success', 'La réunion de cadrage a bien été annulée.')
            loadBeneficiaryDetails()
        } catch (error) {
            addAlert('error', 'Une erreur est survenue lors l\'annulation de la réunion de cadrage.')
        }
    }

    const handleSubmit = async () => { 
        try {   
            const updatedScopingMeeting = {
                entity_id: scopingMeeting?.entity_id,
                provider_id: formFields.provider,
            }
            const { getError } = await CatalogApi.editBackOfficeScopingMeetingEntity(updatedScopingMeeting)
            if (getError()) throw getError()
            addAlert('success', 'L\'intervenant de la réunion de cadrage a bien été remplacé.')
            loadBeneficiaryDetails()
        } catch (error) {
            addAlert('error', 'Une erreur est survenue lors du changement de l\'intervenant.')
        }
    }

    React.useEffect(() => {
        // Si le user est un superviseur du BO gestion, on récupère la liste des prestataires
       if(isBOLeadSupervisor) fetchEntities()
    }, [isBOLeadSupervisor]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(isNotEmpty(providerList)) {
            // On pousse la valeur de la société enregistrée dans scopingMeeting pour avoir la valeur par défaut du select
            setFormFields(s => ({
                ...s,
                provider: providerList?.find((item) => item.value === scopingMeeting?.service_provider_entity_id)
            }))
        }

    }, [providerList]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (event) => {
        const target = event.target
        const name = target.name
        setFormFields(s => ({
            ...s,
            [name]: target.value
        }))
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])


    return (  
        <>
        {((isBOLeadSupervisor && isNotEmpty(scopingMeeting?.framing_meeting_date)) || (!isBOLeadSupervisor && scopingMeeting?.provider_data?.structureName)) && 
            <>
                <div className="u-mg-bottom-s">
                    <RowInformations
                        informations={[
                            {
                                label: 'Société',
                                value: scopingMeeting?.provider_data.structureName
                            }
                        ]}
                    />
                </div>
                <hr className="u-mg-vt-m" />
             </>
        }

        {// On affiche le bloc pour un superviseur admin FUB et si la date de réunion de cadrage n'est pas encore réalisée
        isBOLeadSupervisor && isNotEmpty(scopingMeeting?.provider_data?.structureName) && !isNotEmpty(scopingMeeting?.framing_meeting_date) &&
        <>
            {/* List of advisors */}
            <FormElement
                value={formFields.provider}
                options={providerList}
                name="provider"
                type="select"
                label="Changer le prestataire"
                className="u-pd-vt-m"
                showErrors={showLocalErrors}
                onValidate={handleValidation}
                onChange={handleInputChange}
                isOptionDisabled={(option) => option.disabled}
            />

            <div className="u-flex u-flex-between">
                <button
                    type="button"
                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m  u-pd-s"
                    onClick={handleSubmit}
                    disabled={!isNotEmpty(formFields.provider) || formFields.provider === scopingMeeting?.service_provider_entity_id}
                >
                    Valider
                </button>
                <button
                    type="button"
                    className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-left-m u-pd-s"
                    onClick={() => setFormFields(s => ({
                        ...s,
                        provider: providerList?.find((item) => item.value === scopingMeeting?.service_provider_entity_id)
                    }))}
                    disabled={formFields.provider === scopingMeeting?.service_provider_entity_id}
                >
                    Annuler
                </button>
            </div>

            <hr className="u-mg-vt-m" />
        </>
        }

        {!scopingMeeting?.provider_data?.structureName && (
            <div className="u-grey90 u-fs-xs u-mg-top-s">
                La réunion de cadrage n'a pas été effectuée.
            </div>
        )}

        {scopingMeeting?.create_date && 
            <div className="u-mg-bottom-s">
                <RowInformations
                    informations={[
                        {
                            label: 'Réunion de cadrage demandée le',
                            value: dateService.formatDate(scopingMeeting?.create_date)
                        }
                    ]}
                />
            </div>
        }

        {scopingMeeting?.planned_date && 
            <div className="u-mg-bottom-s">
                <RowInformations
                    informations={[
                        {
                            label: 'Réunion de cadrage planifiée le',
                            value: dateService.formatDate(scopingMeeting?.planned_date)
                        }
                    ]}
                />
            </div>
        }

        {scopingMeeting?.framing_meeting_date && 
            <div className="u-mg-bottom-s">
                <RowInformations
                    informations={[
                        {
                            label: 'Réunion de cadrage réalisée le',
                            value: dateService.formatDate(scopingMeeting?.framing_meeting_date)
                        }
                    ]}
                />
            </div>
        }

        {// On affiche le bloc pour un superviseur admin FUB et si la date de réunion de cadrage n'est pas encore réalisée
        isBOLeadSupervisor && isNotEmpty(scopingMeeting?.provider_data?.structureName) && !isNotEmpty(scopingMeeting?.framing_meeting_date) &&
        <>
            <hr className="u-mg-vt-m" />

            <button
                type="button"
                className="c-btn c-btn--primary u-fs-xs c-btn--s u-pd-s u-mg-vt-m"
                style={{ width: '100%', maxWidth: '460px' }}
                onClick={cancelScopingMeeting}
            >
                Annuler la réunion de cadrage
            </button>
        </>
        }

        {hasScopingMeetingReport && (
            <InformationToVisualize
                label="Consulter le rapport"
                url={scopingMeeting?.framing_meeting_media_rapport_url.full_media_url}
                iconColor="green"
            />
        )}
        {hasScopingMeetingDate && !hasScopingMeetingReport && (
            <>
                <hr className="u-mg-vt-m" />
            
                <div className="u-grey90 u-fs-xs u-mg-top-s">
                    Le rapport de réunion de cadrage n'a pas été envoyé.
                </div>
            </>
        )}
        </>
)}

ScopingMeetingCard.propTypes = {
    isBOLeadSupervisor: bool,
    hasScopingMeetingDate: bool,
    hasScopingMeetingReport: bool,
    scopingMeeting: object,
    loadBeneficiaryDetails: func,
}